.cross {
    margin: 0;
    height: 28px;
    fill: #F14668;
}

.cross:hover {
    cursor: pointer;
    fill: #9A2D42;
}

.check {
    margin: 0;
    height: 28px;
    fill: #00D1B2;
}

.check:hover {
    cursor: pointer;
    fill: #008672;
}
