.inputWrapperPrefix {
    display: none;
    /*background: #202634;*/
}

.form-aa {
    /*background: #202634;*/
    border: none;
    background: inherit;
}
